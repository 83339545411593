import React from 'react'
import { Link, Body } from '@superhi/design'

import { Wrapper } from './styles'

type Props = {
  items: {
    text: string
    href: string
  }[]
}

const FooterItems: React.FC<Props> = ({ items = [] }) => (
  <div>
    <Wrapper data-component-name="FooterItems">
      {items.map((item, i) => (
        <Link key={i} href={item.href}>
          <Body level="3">{item.text}</Body>
        </Link>
      ))}
    </Wrapper>
  </div>
)

export default FooterItems
