import { Form, FormTextInput } from '@superhi/design'
import { useMutation } from '@apollo/client'

import { API } from '../../../../api'
import Page from '../../../../components/Page'
import PageLayout, { SPACING } from '../../../../components/PageLayout'
import { handleErrors } from '../../../../utils'
import { PageConfig } from '../../../../routing'
import FooterItems from '../../../../components/PageLayout/components/FooterItems'
import SignUp from '../../../SignUp'
import LogIn from '../../../LogIn'

import { RESET_PASSWORD_REQUEST } from './mutations'
import { VALIDATION_SCHEMA, FIELD_CONFIGS, FormValues } from './config'
import ROUTE, { PathArgs } from './route'

const ResetPasswordRequest: PageConfig<unknown, PathArgs> = () => {
  const [mutation, result] = useMutation<
    API.ForgottenPassword_ResetRequest,
    API.ForgottenPassword_ResetRequestVariables
  >(RESET_PASSWORD_REQUEST)

  return (
    <Page title="Reset your password">
      <PageLayout
        title={!result.data?.accountPasswordResetRequest ? 'Reset your password' : 'Okay, perfect'}
        subtitle={
          !result.data?.accountPasswordResetRequest
            ? "Enter your account email below and we'll send you a link to reset your password."
            : "Go to your email inbox and look for our password reset email. Click the link in the email and you'll be able to reset your password."
        }
        footer={
          !result.data?.accountPasswordResetRequest && (
            <FooterItems
              items={[
                {
                  text: 'Create an account',
                  href: SignUp.route.path(),
                },
                {
                  text: 'Already have an account?',
                  href: LogIn.route.path(),
                },
              ]}
            />
          )
        }
      >
        {!result.data?.accountPasswordResetRequest && (
          <Form<FormValues>
            name="forgotten-password"
            initialValues={{
              email: '',
            }}
            validationSchema={VALIDATION_SCHEMA}
            spacing={SPACING}
            onSubmit={async (values, helpers) => {
              try {
                await mutation({ variables: values })
              } catch (e) {
                handleErrors(e, helpers.setFieldError, {
                  user: 'email',
                })
              }
            }}
            submitButtonText="Reset password"
            submitButtonAlign="center"
          >
            <Form.Rows>
              <FormTextInput {...FIELD_CONFIGS.EMAIL} />
            </Form.Rows>
          </Form>
        )}
      </PageLayout>
    </Page>
  )
}

ResetPasswordRequest.route = ROUTE

export default ResetPasswordRequest
