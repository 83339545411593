import { FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'
import { ObjectSchemaDefinition } from 'yup'
import { string } from 'yup'

export type FormValues = {
  username: string
  password: string
}

export const USERNAME: FieldConfig<typeof FormTextInput> = {
  name: 'username',
  label: 'Username or email address',
  ariaLabel: 'Username or email address',
  placeholder: 'e.g. hi@superhi.com',
  required: true,
}

export const PASSWORD: FieldConfig<typeof FormTextInput> = {
  name: 'password',
  type: 'password',
  label: 'Password',
  ariaLabel: 'Password',
  placeholder: '···········',
  required: true,
}

export const FIELD_CONFIGS = {
  USERNAME,
  PASSWORD,
}

export const VALIDATION_SCHEMA: ObjectSchemaDefinition<FormValues> = {
  username: string().required(),
  password: string().required(),
}
