import { theme } from '@superhi/design'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin: -8px;

  @media ${theme.breakpoints.medium} {
    margin: -12px;
  }

  > * {
    margin: 8px;

    @media ${theme.breakpoints.medium} {
      margin: 12px;
    }
  }
`
