import { gql } from '@apollo/client'

import { FRAGMENT } from '../../api/auth/queries'

export const LOG_IN = gql`
  mutation LogIn_SessionCreate($username: String!, $password: String!) {
    sessionCreate(username: $username, password: $password) {
      token
      ...Auth_Session
    }
  }

  ${FRAGMENT}
`
