import React from 'react'
import { Form, FormCheckboxInput, FormTextInput } from '@superhi/design'
import { useMutation } from '@apollo/client'

import { API } from '../../api'
import authTokenCookie from '../../api/auth/cookie'
import { SESSION } from '../../api/auth/queries'
import Page from '../../components/Page'
import PageLayout, { SPACING } from '../../components/PageLayout'
import { handleErrors } from '../../utils'
import { PageConfig } from '../../routing'
import FooterItems from '../../components/PageLayout/components/FooterItems'
import LogIn from '../LogIn'

import { SIGN_UP } from './mutations'
import { FIELD_CONFIGS, FormValues, VALIDATION_SCHEMA } from './config'
import ROUTE, { PathArgs } from './route'

const SignUp: PageConfig<unknown, PathArgs> = () => {
  const [signUp] = useMutation<API.SignUp_AccountRegister, API.SignUp_AccountRegisterVariables>(
    SIGN_UP,
  )

  return (
    <Page title="Sign up">
      <PageLayout
        title="Sign up"
        footer={
          <FooterItems
            items={[
              {
                text: 'Already have an account?',
                href: LogIn.route.path(),
              },
            ]}
          />
        }
      >
        <Form<FormValues>
          name="sign-up"
          initialValues={{
            username: '',
            email: '',
            name: '',
            password: '',
            passwordConfirmation: '',
            codeOfConduct: false,
          }}
          spacing={SPACING}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={async (values, helpers) => {
            try {
              await signUp({
                variables: values,
                update(cache, { data }) {
                  const session = data?.accountRegister

                  if (session) {
                    if (session.token) {
                      authTokenCookie.set(session.token)
                    }

                    cache.writeQuery({
                      query: SESSION,
                      data: { session },
                    })
                  } else {
                    authTokenCookie.remove()
                  }
                },
              })
            } catch (e) {
              handleErrors(e, helpers.setFieldError)
            }
          }}
          submitButtonAlign="center"
          submitButtonText="Sign up"
        >
          <Form.Rows>
            <FormTextInput {...FIELD_CONFIGS.NAME} />
            <FormTextInput {...FIELD_CONFIGS.USERNAME} />
            <FormTextInput {...FIELD_CONFIGS.EMAIL} />
            <FormTextInput {...FIELD_CONFIGS.PASSWORD} />
            <FormTextInput {...FIELD_CONFIGS.PASSWORD_CONFIRMATION} />
            <FormCheckboxInput {...FIELD_CONFIGS.CODE_OF_CONDUCT} />
          </Form.Rows>
        </Form>
      </PageLayout>
    </Page>
  )
}

SignUp.route = ROUTE

export default SignUp
