import { FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'
import { ObjectSchemaDefinition, string } from 'yup'

export type FormValues = {
  email: string
}

export const EMAIL: FieldConfig<typeof FormTextInput> = {
  name: 'email',
  label: 'Email',
  ariaLabel: 'Email',
  placeholder: 'e.g. hi@superhi.com',
  required: true,
}

export const FIELD_CONFIGS = {
  EMAIL,
}

export const VALIDATION_SCHEMA: ObjectSchemaDefinition<FormValues> = {
  email: string().required(),
}
