import React from 'react'
import { Form, FormTextInput } from '@superhi/design'
import { useMutation } from '@apollo/client'

import { SESSION } from '../../api/auth/queries'
import { API } from '../../api'
import authTokenCookie from '../../api/auth/cookie'
import PageLayout, { SPACING } from '../../components/PageLayout'
import Page from '../../components/Page'
import { handleErrors } from '../../utils'
import { PageConfig } from '../../routing'
import SignUp from '../SignUp'
import ResetPasswordRequest from '../ResetPassword/pages/Request'
import FooterItems from '../../components/PageLayout/components/FooterItems'

import { LOG_IN } from './mutations'
import { FormValues, FIELD_CONFIGS, VALIDATION_SCHEMA } from './config'
import ROUTE, { PathArgs } from './route'

const LogIn: PageConfig<unknown, PathArgs> = () => {
  const [login] = useMutation<API.LogIn_SessionCreate, API.LogIn_SessionCreateVariables>(LOG_IN)

  return (
    <Page title="Log in">
      <PageLayout
        title="Log in"
        footer={
          <FooterItems
            items={[
              {
                text: 'Forgot password?',
                href: ResetPasswordRequest.route.path(),
              },
              { text: 'Need an account?', href: SignUp.route.path() },
            ]}
          />
        }
      >
        <Form<FormValues>
          name="log-in"
          initialValues={{ username: '', password: '' }}
          validationSchema={VALIDATION_SCHEMA}
          spacing={SPACING}
          onSubmit={async (values, helpers) => {
            try {
              await login({
                variables: values,
                update(cache, { data }) {
                  const session = data?.sessionCreate

                  if (session?.token) {
                    authTokenCookie.set(session.token)
                  } else {
                    authTokenCookie.remove()
                  }

                  if (data?.sessionCreate) {
                    cache.writeQuery({
                      query: SESSION,
                      data: { session: data.sessionCreate },
                    })
                  }
                },
              })
            } catch (e) {
              handleErrors(e, helpers.setFieldError)
            }
          }}
          submitButtonAlign="center"
          submitButtonText="Log in"
        >
          <Form.Rows>
            <FormTextInput {...FIELD_CONFIGS.USERNAME} />
            <FormTextInput {...FIELD_CONFIGS.PASSWORD} />
          </Form.Rows>
        </Form>
      </PageLayout>
    </Page>
  )
}

LogIn.route = ROUTE

export default LogIn
