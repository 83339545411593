import { FormTextInput, Link } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'
import { ObjectSchemaDefinition } from 'yup'
import { boolean, ref, string } from 'yup'

import { MARKETING_SITE_URL } from '../../config'

export type FormValues = {
  email: string
  username: string
  name: string
  password: string
  passwordConfirmation: string
  codeOfConduct: boolean
}

export const NAME: FieldConfig<typeof FormTextInput> = {
  name: 'name',
  label: 'Full name',
  ariaLabel: 'Full name',
  placeholder: 'e.g. Elaine Benes',
  required: true,
}

export const USERNAME: FieldConfig<typeof FormTextInput> = {
  name: 'username',
  label: 'Username',
  ariaLabel: 'Username',
  placeholder: 'e.g. elainebenes',
  required: true,
}

export const EMAIL: FieldConfig<typeof FormTextInput> = {
  name: 'email',
  label: 'Email',
  ariaLabel: 'Email',
  placeholder: 'e.g. hi@superhi.com',
  required: true,
}

export const PASSWORD: FieldConfig<typeof FormTextInput> = {
  name: 'password',
  type: 'password',
  label: 'Password',
  ariaLabel: 'Password',
  placeholder: '···········',
  required: true,
}

export const PASSWORD_CONFIRMATION: FieldConfig<typeof FormTextInput> = {
  name: 'passwordConfirmation',
  type: 'password',
  label: 'Confirm password',
  ariaLabel: 'Confirm password',
  placeholder: '···········',
  required: true,
}

export const CODE_OF_CONDUCT: FieldConfig<typeof FormTextInput> = {
  name: 'codeOfConduct',
  label: 'Code of Conduct',
  ariaLabel: 'Code of Conduct',
  children: (
    <>
      {' '}
      I agree to the{' '}
      <Link href={`${MARKETING_SITE_URL}/code-of-conduct`} isExternal>
        Community Code of Conduct
      </Link>
    </>
  ),
  required: true,
}

export const FIELD_CONFIGS = {
  NAME,
  USERNAME,
  EMAIL,
  PASSWORD,
  PASSWORD_CONFIRMATION,
  CODE_OF_CONDUCT,
}

export const VALIDATION_SCHEMA: ObjectSchemaDefinition<FormValues> = {
  username: string().required(),
  email: string().required(),
  name: string().required(),
  password: string().required(),
  passwordConfirmation: string()
    .oneOf([ref('password'), undefined], 'Passwords must match')
    .required(),
  codeOfConduct: boolean().oneOf([true], 'Must be checked').required(),
}
