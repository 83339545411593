import { gql } from '@apollo/client'

import { FRAGMENT } from '../../api/auth/queries'

export const SIGN_UP = gql`
  mutation SignUp_AccountRegister(
    $username: String!
    $email: String!
    $name: String
    $password: String!
  ) {
    accountRegister(username: $username, email: $email, name: $name, password: $password) {
      token
      ...Auth_Session
    }
  }

  ${FRAGMENT}
`
