import { gql } from '@apollo/client'

export const FRAGMENT = gql`
  fragment Auth_Session on Session {
    id
    membership {
      id
      status
    }
  }
`

export const SESSION = gql`
  query Auth_Session {
    session {
      ...Auth_Session
    }
  }

  ${FRAGMENT}
`
